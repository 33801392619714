import {
    Container,
    Grid,
    SimpleGrid,
    Skeleton,
    useMantineTheme,
    rem,
    Paper,
    Group,
    Card,
    Text,
    Menu,
    ActionIcon,
    Switch, Collapse, Image, Flex, Title, Button, Stack, Center, Anchor, createStyles, Modal, Space
} from '@mantine/core';
import {Uploader} from "../Uploader/Uploader";
import { WebsiteHeader} from "./WebsiteHeader";
import {
    IconArrowRight, IconCirclePlus,
    IconDots,
    IconEye,
    IconFileZip,
    IconHeartHandshake,
    IconHelp,
    IconLink,
    IconMail,
    IconTrash
} from "@tabler/icons-react";
import {TransfersList} from "../Account/TransfersList";
import {useContext, useEffect, useState} from "react";
import {websiteContext} from "../data/websiteContext";

import {isMacOs} from "react-device-detect";

import commentingImage from "../../assets/img/comments.png"
import chatBubbles from "../../assets/img/ChatBubbles.png"
import lock from "../../assets/img/lock.png"
import background from '../../assets/img/gradient-bg.jpeg';
import {Future} from "./Future";
import image from "../../assets/img/gradient-bg.jpeg";
import noAd from "../../assets/img/noAd.png";
import previews from "../../assets/img/previews.png";
import ownDomain from "../../assets/img/ownDomain.png";
import donate from "../../assets/img/donate.png";
import brand from "../../assets/img/brand.png";
import instance from "../../assets/img/instance.png";
import {Future2} from "./Future2";
import {ButtonGroup, Content, Dialog, DialogContainer, Divider, Heading, useProvider} from "@adobe/react-spectrum";
import {MasonryGrid} from "./Masonry/Masonry";
import AuthHandler from "../data/AuthHandler";
import BigAvatar from "./BigAvatar/BigAvatar";
import {Footer} from "./Footer";
import { useMediaQuery } from '@mantine/hooks';
import {SignUpEngagement} from "../Marketing/SignUpEngagement";
import {MacOSEngagement} from "../Marketing/MacOSEngagement";
import Cookies from "js-cookie";

const PRIMARY_COL_HEIGHT = rem(300);

const useStyles = createStyles((theme) => ({
    root: {
        marginBottom: 100,

        [theme.fn.smallerThan('xs')]: {
            marginBottom: 40
        },
    }
}));


export function SpacePage({title, member}) {
    const theme = useMantineTheme();
    const SECONDARY_COL_HEIGHT = `calc(${PRIMARY_COL_HEIGHT} / 2 - ${theme.spacing.md} / 2)`;

    const { classes, cx } = useStyles();

    const [macOSAd, setMacOSAd] = useState(false)


    const websiteData = useContext(websiteContext);

    const [shareType, setShareType] = useState("link");

    const [hasLoaded, setIsLoaded] = useState(false);

    const [avatarLoaded, setAvatarLoaded] = useState(true);

    const [helpSendOpen, setHelpSendOpen] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const premiumSuccess = urlParams.has('premiumSuccess');

    const [upgradeOpen, setUpgradeOpen] = useState(premiumSuccess)
    const isMobile = useMediaQuery("(max-width: 50em)");

    const isYsenditCom = window.location.hostname === "ysendit.com";


    return (
        <>
            <Container>
                <WebsiteHeader links={[]} active="" />

                <Center className={classes.root}>
                    <Flex
                        gap="md"
                        justify="center"
                        align="center"
                        direction="row"
                        wrap="wrap"
                    >


                        <BigAvatar src={(websiteData.user != null && websiteData.user.avatar != null) ? process.env.REACT_APP_YSNDIT_ENDPOINT + "assets/img/custom/avatars/" + websiteData.user.avatar : null} />
                        <Stack spacing="xs">
                            <Title order={1}>Share files with {title}</Title>

                            {(websiteData.user != null && websiteData.user.authenticated) &&
                                <Text fw={500} fz="xl">Welcome back, {websiteData.user.name}! <Anchor href={process.env.REACT_APP_YSNDIT_ENDPOINT + "profile?redirect_to=" + encodeURI(window.location.href)}>Update profile</Anchor> or <Anchor href={process.env.REACT_APP_YSNDIT_ENDPOINT + "user/logout?redirect=" + encodeURI(window.location.href)}>
                                    logout
                                </Anchor></Text>
                            }

                            {(websiteData.user == null || !websiteData.user.authenticated) &&
                                <Text fw={500} fz="xl">Happy to meet you! Lets make it official, <Anchor onClick={() => {const handler = new AuthHandler(); handler.authorize();}}>
                                    Sign up or Log in
                                </Anchor>.</Text>
                            }
                        </Stack>



                    </Flex>




                </Center>

                <Grid cols={2} spacing="md"  mb={30}>

                    <Grid.Col style={{width:"300px"}} sm="content" span={12} >
                        {(websiteData.consent != null && websiteData.user != null) &&
                            <Card withBorder shadow="sm" radius="md" w="280px" style={{overflow: "hidden", display: (hasLoaded) ? "block":"none"}}>

                                {(!websiteData.user.mobile) &&
                                    <Card.Section withBorder inheritPadding py="xs">
                                        <Group position="apart">
                                            <Group spacing="xs">
                                                <Text weight={500}>Send files to {title}</Text>
                                                <ActionIcon onClick={() => {setHelpSendOpen(!helpSendOpen)}} size="xs" color="blue" variant={(helpSendOpen) ? "filled" : "default"}>
                                                    <IconHelp size="0.9rem" />
                                                </ActionIcon>
                                            </Group>
                                        </Group>
                                    </Card.Section>
                                }

                                {helpSendOpen &&
                                    <Collapse in={helpSendOpen}>
                                        <Text mt="sm" mb="sm" color="dimmed" size="sm">
                                            Choose the files you want to share with {title}. They'll be notified by mail and can download them.
                                        </Text>

                                    </Collapse>
                                }


                                <Card.Section>

                                    <Uploader browserId={websiteData.user.browserId} setIsLoaded={setIsLoaded} consent={JSON.stringify(websiteData.consent)} borderRadius="0px" blank noBorder shareType={websiteData.user.id === member ? "mail" : "link"} space_id={window.location.pathname.slice(1)} verifySender="true" darkMode={theme.colorScheme === 'dark'}/>

                                </Card.Section>
                            </Card>
                        }
                        {(websiteData.consent == null || websiteData.user == null || (!hasLoaded && !websiteData.user.mobile)) &&
                            <Skeleton w="100%" height={SECONDARY_COL_HEIGHT} radius="md" animate={true} />
                        }




                    </Grid.Col>
                    <Grid.Col sm="auto" span={12} >


                        {(websiteData.consent != null && websiteData.user != null) &&
                            <Card withBorder shadow="sm" radius="md">
                                <Card.Section withBorder inheritPadding py="xs">
                                    <Group position="apart">
                                        <Text weight={500}>Shared with {title}</Text>
                                    </Group>
                                </Card.Section>
                                <Card.Section withBorder inheritPadding py="xs">
                                    <TransfersList user={websiteData.user} space_id={window.location.pathname.slice(1)} />
                                </Card.Section>

                                {(!websiteData.user.authenticated) &&
                                    <div>
                                        <Grid grow justify="space-between" align="center">
                                            <Grid.Col span={6}>
                                                <Text weight={500} size="lg" mt="md">
                                                    You are not logged in. Shared transfers may disappear.
                                                </Text>
                                            </Grid.Col>
                                            <Grid.Col span="auto">
                                                <Button onClick={() => {const handler = new AuthHandler(); handler.authorize();}} variant="default" mt="md" w="100%" color="blue" radius="md">
                                                    Sign up
                                                </Button>
                                            </Grid.Col>
                                        </Grid>
                                        <Text mt="xs" color="dimmed" size="sm">
                                            With a free account you can list all transfers you have started in the past and delete them before their expiry date.
                                        </Text>
                                    </div>
                                }


                            </Card>
                        }
                        {(websiteData.consent == null || websiteData.user == null) &&
                            <Skeleton height={SECONDARY_COL_HEIGHT} radius="md" animate={true} />
                        }

                    </Grid.Col>
                </Grid>

                <Footer macApp={false} links={[{link: "https://nwebr.atlassian.net/servicedesk/customer/portal/5", label: "Contact"}, {link: "https://ysendit.statuspage.io", label: "System status"}, {link: (websiteData.siteData != null) ? websiteData.siteData.privacyUrl : "/privacy", label: "Privacy"}, {link: (websiteData.siteData != null) ? websiteData.siteData.termsUrl : "/terms", label: "Terms"}, {link: (websiteData.siteData != null) ? websiteData.siteData.imprintUrl : "/imprint", label: "Imprint"}]} />
            </Container>
        </>
    );
}