import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Content, Dialog, DialogContainer, Divider, Footer, Heading, Link, Text, View } from '@adobe/react-spectrum';
import { Alert } from '@mantine/core';
import ConsentSettings from './ConsentSettings';
import { IconInfoCircle } from '@tabler/icons-react';
import { useMediaQuery } from 'react-responsive';
import { ConsentHandler } from './Consent';
import './revokeConsent.css';


const ConsentModal = ({requestedPage = "download", isRevoke, callback, reactCallback, preflight, reactPreflight, siteName, logo}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 470px)' })
  const modalType = (isMobile) ? "fullscreenTakeover" : "modal";

  const [consentOptions, setConsentOptions] = useState(null);


  // callback is a js function that gets called; reactCallback is a useState setter in react that gets called

    const [isOpen, setOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);

    const [consentHandler, setConsentHandler] = useState(null);
    const [deletedCookies, setDeletedCookies] = useState(false);

    function callCallback(consent) {
      if(callback != null && callback != "") {
        window[callback](consent);
      }
      if(reactCallback != null && reactCallback != "") {
        reactCallback(consent);
      }
    }

    function preflightBack(preflightData) {
      if(preflight != null && preflight != "") {
        window[preflight](preflightData);
      }
      if(reactPreflight != null && reactPreflight != "") {
        reactPreflight(preflightData);
      }
    }

    function saveConsent(consentOptions, reject) {
      callCallback(consentHandler.saveConsent(consentOptions, reject));
      setOpen(false);
    }

    function deleteCookies() {
      localStorage.clear();
      sessionStorage.clear();
      window.open(process.env.REACT_APP_YSNDIT_ENDPOINT + "api/client/consent/deleteCookies","_self");
      }

      function deleteCookie() {
        var Cookies = document.cookie.split(';');
 // set past expiry to all cookies
for (var i = 0; i < Cookies.length; i++) {
  console.log(Cookies[i]);
   document.cookie = Cookies[i] + "=; expires="+ new Date(0).toUTCString()+";  path=/;";
}
localStorage.clear();
setDeletedCookies(true);
      }

    useEffect(() => {
      let tempConsentHandler = new ConsentHandler(requestedPage, siteName, logo);
      let tempConsent = tempConsentHandler.consent;
      setConsentOptions(tempConsent.consentOptions)
      tempConsentHandler.check(setConsentHandler, preflightBack).then((hasConsent) => {
        if(!isRevoke) {
          if(!hasConsent) {
            setOpen(true);
          }
          else {
            callCallback(tempConsent);
            setOpen(false);
          }
        }
        else {
          setSettingsOpen(true);
        }
      })
      
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);


   return (
    <>
    {(isRevoke && !deletedCookies && consentHandler != null && !consentHandler.newConsent) &&
    <div style={{backgroundColor: "transparent"}}>
      <Button backgroundColor="transparent" variant="accent" onPress={() => {setOpen(true)}} style="fill">Adjust your consent</Button>
      </div>
    }
        <DialogContainer
        isDismissable={false}
        type={modalType}
        isKeyboardDismissDisabled={false}
      >
         {(isOpen && consentHandler != null) &&
         <Dialog size="M">
          {!isRevoke &&
          <Heading>Before you continue to {(consentHandler.siteName && consentHandler.siteName != "") ? consentHandler.siteName : consentHandler.consent.consentData.siteName}</Heading>
          }
          {isRevoke &&
          <Heading>Adjust your consent for {(consentHandler.siteName && consentHandler.siteName != "") ? consentHandler.siteName : consentHandler.consent.consentData.siteName}</Heading>
          }
         <Divider />
         <ButtonGroup>
         {!settingsOpen &&
           <Button variant="secondary" onPress={() => {setSettingsOpen(true)}}>Settings</Button>
         }
         {(settingsOpen && !isRevoke) &&
           <Button variant="secondary" onPress={() => {saveConsent(consentOptions, true)}}>Reject all optionals</Button>
         }
         {(settingsOpen && isRevoke) &&
           <Button variant="secondary" onPress={() => {deleteCookies(); setOpen(false);}}>Reject all & delete cookies</Button>
         }
         {!settingsOpen &&
           <Button autoFocus variant="accent" onPress={() => {saveConsent(consentOptions, false)}}>Accept all</Button>
         }
         {settingsOpen &&
           <Button variant="accent" onPress={() => {saveConsent(consentOptions, false)}}>Save</Button>
         }
         </ButtonGroup>
         <Content UNSAFE_style={{overflowX:"hidden"}}>

{(consentHandler.outdatedConsent) &&
         <Alert mb={15} icon={<IconInfoCircle size="1rem" />}>
         Since your last visit, some changes have been made{(consentHandler.consent.consentData.whatChanged != "") ? (" to " + consentHandler.consent.consentData.whatChanged) : ""}. Please give us your consent for this change.
    </Alert>
}

{consentHandler.additionalConsentRequired &&
    <Alert mb={15} icon={<IconInfoCircle size="1rem" />}>
    To continue we need further permissions from you.
    </Alert>
}

         {!settingsOpen &&
         <>
<Text>
We need your consent to use our website.
By clicking "Accept all" you agree to the use of cookies, our <Link><a href={consentHandler.consent.consentData.termsUrl} target="_blank">terms</a></Link> and the <Link><a href={consentHandler.consent.consentData.privacyUrl} target="_blank">privacy policy</a></Link>. You can adjust or revoke your consent anytime on the <Link><a href={consentHandler.consent.consentData.privacyUrl} target="_blank">privacy page</a></Link>.
</Text>

<View marginTop="15px" isHidden={{ base: false, S: true }}>
<Link onPress={() => {setSettingsOpen(true)}}>
          Show an overview
</Link>
</View>
</>
}
{settingsOpen &&
    <ConsentSettings consentHandler={consentHandler} consentOptions={consentOptions} setConsentOptions={setConsentOptions} />
}



         </Content>
         {!settingsOpen &&
         <Footer isHidden={{ base: true, S: false }}>
        <Link onPress={() => {setSettingsOpen(true)}}>
          Show an overview
        </Link>
      </Footer>
}
       </Dialog>
      }
      </DialogContainer>
      </>
   )
}


export default ConsentModal;