import { ActionButton, ActionGroup, ActionMenu, AlertDialog, Button, ButtonGroup, Cell, Column, Content, Dialog, DialogContainer, DialogTrigger, Divider, Flex, Header, Heading, IllustratedMessage, Item, LabeledValue, Link, ListView, Row, StatusLight, TableBody, TableHeader, TableView, Text, Tooltip, TooltipTrigger, useAsyncList, useDialogContainer } from '@adobe/react-spectrum';
import Delete from '@spectrum-icons/workflow/Delete';

import NotFound from '@spectrum-icons/illustrations/NotFound';
import {useEffect, useState} from 'react';
import Copy from '@spectrum-icons/workflow/Copy';

export function TransfersList({user, setIsUploaderOpen, fullHeight, reload, space_id}) {

    const [transferToDelete, setTransferToDelete] = useState(null);
    const [disabledRows, setDisabledRows] = useState(new Set());
    const [downloadUrl, setDownloadUrl] = useState("");

    const urlParams = new URLSearchParams(window.location.search);


    function deleteUpload(uploadId) {
        // POST request using fetch inside useEffect React hook
        var formdata = new FormData();
        formdata.append("upload_id", uploadId);
        formdata.append("browser_id", user.browserId);

        var myHeaders = new Headers();
        /*
        if (process.env.NODE_ENV == 'development') {
            myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
            myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
        }
         */

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',
            credentials: 'include'
        };
        fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "api/v1/client/transfers/delete", requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data["deleted"]) {
                    list.reload();
                }
                else {
                    list.reload();
                }
            });
    }

    function openTransfer(upload_id) {
        const item = list.items.find((item) => item.upload_id == upload_id)
        if(item["status"] == "ready") {
            if(urlParams.has("transferListWidget")) {
                window.open(process.env.REACT_APP_YSNDIT_ENDPOINT + upload_id + "/" + item["secret_code"], '_parent');
            }
            else {
                window.open(process.env.REACT_APP_YSNDIT_ENDPOINT + upload_id + "/" + item["secret_code"], '_blank').focus();
            }
        }
    }

    useEffect(() => {
        list.reload()
    }, [user])

    useEffect(() => {
        const handler = (ev) => {
            if(ev.data && ev.data.uploadCompleted) {
                list.reload()
            }
        }

        window.addEventListener('message', handler)

        // Don't forget to remove addEventListener
        return () => window.removeEventListener('message', handler)
    }, [])

    let list = useAsyncList({
        async load({ signal, cursor }) {
            if (cursor) {
                cursor = cursor.replace(/^http:\/\//i, 'https://');
            }

            var myHeaders = new Headers();
            /*
            if (process.env.NODE_ENV == 'development') {
                myHeaders.append("CF-Access-Client-Id", process.env.REACT_APP_CF_ID);
                myHeaders.append("CF-Access-Client-Secret", process.env.REACT_APP_CF_SECRET);
            }
             */
            myHeaders.append("Browser-Id", user.browserId);
            myHeaders.append("Authorization", localStorage.getItem("token"));
            if(space_id && space_id !== "") {
                myHeaders.append("Space-Id", space_id);
            }

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
                signal: signal,
                credentials: 'include'
            };


            let res = await fetch(
                cursor || process.env.REACT_APP_YSNDIT_ENDPOINT + "api/v1/client/transfers/get/1",
                requestOptions,
                { signal }
            );
            let json = await res.json();

            if(json.results.length > 0) {
                setDownloadUrl(json.download_url);
                let disabledKeys = new Set(disabledRows);
                json.results.every(function(item, key) {
                    if(item.status != "ready") {
                        disabledKeys.add(item.upload_id);
                    }
                    const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit' };
                    item.time = (new Date(item.time * 1000)).toLocaleTimeString(undefined, options);
                    item.time_expire = (new Date(item.time_expire * 1000)).toLocaleTimeString(undefined, options);



                    return true;
                });
                setDisabledRows(disabledKeys);
            }

            return {
                items: json.results,
                cursor: json.next
            };
        }
    });

    function renderEmptyState() {
        return (
            <IllustratedMessage>
                <NotFound />
                <Heading>No transfers</Heading>
                <Content>No uploads found. Use an account to keep your transfers</Content>
            </IllustratedMessage>
        );
    }

    function performAction(key) {
        const data = key.split("&#&");
        if(data[0] == "copy") {
            navigator.clipboard.writeText(downloadUrl + data[1]);
        }
        else if(data[0] == "delete") {
            setTransferToDelete(data[1]);
        }
    }

    useEffect(() => {
        if(reload > 0) {
            list.reload()
        }
    }, [reload]);

    return (

<>
                <ListView
                    items={list.items}
                    loadingState={list.loadingState}
                    onLoadMore={list.loadMore}
                    height={fullHeight ? "100%" : ""}
                    minHeight={fullHeight ? "" : "250px"}
                    maxHeight={fullHeight ? "" : "50vh"}
                    onAction={(index) => openTransfer(index)}
                    renderEmptyState={renderEmptyState}
                    disabledKeys={disabledRows}
                    isQuiet
                    density="spacious"
                >
                    {(item) => <Item key={item.upload_id} hasChildItems={item.status == "ready"}>
                        <Text>
                            {(item.transfer_name != "" && item.transfer_name) ? (item.transfer_name + " (" + item.upload_id + ")") : item.upload_id}
                        </Text>
                        {(space_id && space_id !== "") &&
                        <Text slot='description'>
                            {item.sendFromSpaceMember ? ("To " + item.email_to) : ("From " + item.email_from)} | {item.size} | {item.status} | {item.total_downloads} download{(item.total_downloads == 1) ? "" : "s"} | Created {item.time} | Expires {item.time_expire} {item.e2e === "yes" ? "| E2E encrypted" : ""}
                        </Text>
                        }
                        {!(space_id && space_id !== "") &&
                            <Text slot='description'>
                                {item.size} | {item.status} | {item.total_downloads} download{(item.total_downloads == 1) ? "" : "s"} | Created {item.time} | Expires {item.time_expire} {item.e2e === "yes" ? "| E2E encrypted" : ""}
                            </Text>
                        }

                        {(item.email_from == "" || item.status == "ready") &&
                            <ActionGroup buttonLabelBehavior="hide" overflowMode="collapse" onAction={performAction}>
                                {(item.email_from == "") &&
                                    <Item key={"copy&#&" + item.upload_id} textValue="copy">
                                        <Copy size="S" />
                                        <Text>Copy sharable link</Text>
                                    </Item>
                                }
                                {(item.status == "ready") &&
                                    <Item key={"delete&#&" + item.upload_id} textValue="delete">
                                        <Delete size="S" />
                                        <Text>Delete</Text>
                                    </Item>
                                }
                            </ActionGroup>
                        }

                    </Item>}

                </ListView>
                <DialogContainer onDismiss={() => {setTransferToDelete(null)}}>
                    {transferToDelete != null &&
                        <AlertDialog
                            variant="destructive"
                            title="Delete transfer"
                            primaryActionLabel="Delete"
                            onPrimaryAction={() => deleteUpload(transferToDelete)}
                            cancelLabel="Cancel">
                            This will permanently delete the selected transfer. Continue?
                        </AlertDialog>
                    }
                </DialogContainer>

</>
    );
}
