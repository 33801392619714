import logo from './../../assets/img/ysndit-home-icon-light.png';



import { ActionGroup, Item, Text, defaultTheme, Button, View, Flex, ActionButton, Dialog, DialogTrigger, Heading, Image, Divider, Content, ToggleButton, Tabs, TabList, TabPanels } from '@adobe/react-spectrum';

import './Uploader.css';
import {useState} from "react";


export function Uploader({animate, consent, blank = false, borderRadius = "30px", noBorder = true, shareType = "", darkMode = false, setIsLoaded, browserId = "", space_id = "", verifySender = false}) {

  const uploader = (<iframe height="320px" borderWidth="0px" allowTransparency onLoad={() => { setIsLoaded(true)}} style={{border: "none", borderRadius:borderRadius}} width="280px" src={process.env.REACT_APP_YSNDIT_ENDPOINT + "upload.widget?border_radius=0" + ((noBorder) ? "&no_border=true":"") + ((shareType != "") ? ("&shareType=" + shareType) : "")  + "&dark_mode=" + darkMode + "&browser_id=" + browserId + (space_id ? ("&space_id=" + space_id) : "") + "&verifySender=" + verifySender + "&consent=" + consent}></iframe>);

  if(blank) {
    return(uploader)
  }

  return (
    
    <View
    UNSAFE_className={'uploader ' + ((animate) ? "show" : "")}
    alignSelf="center"
    borderWidth="thin"
    backgroundColor="static-white"
    borderColor="dark"
    borderRadius="large"
  height="350px"
  width="280px"
  overflow="hidden"
  >
      {uploader}
  </View>
    
  );
}