import {Button, Card, Grid, Group, MantineProvider, Text} from '@mantine/core';
import './App.css';


import { useProvider } from '@adobe/react-spectrum';
import {useEffect, useState} from 'react';
import {HomePage} from "./Components/Website/HomePage";

import {getUser, websiteContext} from "./Components/data/websiteContext";

import User from "./User";
import {ConsentHandler} from "./Components/ConsentModal/components/Consent";
import ConsentModal from "./Components/ConsentModal/components/ConsentModal";
import {initSiteData} from "./Components/data/websiteContext";
import posthog from "posthog-js";
import {TransfersList} from "./Components/Account/TransfersList";
import AuthHandler from "./Components/data/AuthHandler";
import {SpacePage} from "./Components/Website/SpacePage";

function App() {
  let { colorScheme } = useProvider();

  const [siteData, setSiteData] = useState({consent: null, user: null, siteData: null});

  const urlParams = new URLSearchParams(window.location.search);

  function consentGiven(consent) {
      getUser(logo, title).then((user) => {
          setSiteData({consent: consent, ...user})
      })
    if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
      posthog.init('phc_6Ft66C6ijHSio8nVqv6PdsiBVLekIbICerNu9umtrGE',{api_host:'https://app.posthog.com', opt_in_site_apps: true, disable_persistence: !(consent["consentOptions"]["analyticsCookie"])});
    }
    ConsentHandler.appendScript("https://ysendit.statuspage.io/embed/script.js", {}, "body");
  }

  const [logo, setLogo] = useState(undefined)
  const [title, setTitle] = useState(undefined)
  const [member, setMember] = useState(undefined)
  const isSpace = window.location.pathname.length > 1 && window.location.pathname !== "upload.page"
  useEffect(() => {
    if(isSpace) {
      fetch(process.env.REACT_APP_YSNDIT_ENDPOINT + "spaces/get" + window.location.pathname).then((response) => {
        response.json().then((json) =>
        {
          setLogo("/assets/img/custom/" + json.logo)
          setTitle(json.title)
          setMember(json.member)
        })
      })

    }
  }, [])

  function setUser() {
    getUser(logo, title).then((user) => {
      setSiteData({ consent: siteData.consent, ...user })
    })
  }

  useEffect(() => {

    window.document.addEventListener("tokenUpdated", setUser);
    return () => {
      // unsubscribe event
      window.document.removeEventListener("tokenUpdated", setUser);
    };
  }, [siteData])

  const [reload, setReload] = useState(0)

  return (
      <MantineProvider theme={{ colorScheme: ((colorScheme == 'dark') ? 'dark' : 'light'),
  colors: {
    blue: [
      "#E5F2FF",
      "#B8DBFF",
      "#8AC4FF",
      "#5CADFF",
      "#2E96FF",
      "#007FFF",
      "#0066CC",
      "#004C99",
      "#003366",
      "#001933"
    ],
    dark: [
      "#ebebeb",
      "#C1C2C5",
      "#A6A7AB",
      "#909296",
      "#5C5F66",
      "#373A40",
      "#2C2E33",
      "#25262B",
      "#1A1B1E",
      "#141517"
    ]
  }, primaryColor: 'blue', globalStyles: (theme) => ({


          body: {
            background: (theme.colorScheme === 'dark') ? "linear-gradient(135deg, rgba(48,50,99,1) 0%, rgba(22,22,22,1) 50%, rgba(19,60,103,1) 100%)" : "linear-gradient(135deg, rgba(238,242,255,1) 0%, rgba(255,255,255,1) 50%, rgba(207,250,254,1) 100%)",
          },
        })}} >
        <websiteContext.Provider value={siteData}>
          {(isSpace && title && logo) &&
            <ConsentModal requestedPage='upload' siteName={title} logo={logo} reactCallback={consentGiven} reactPreflight={initSiteData} />
          }
          {!isSpace &&
              <ConsentModal requestedPage='upload' reactCallback={consentGiven} reactPreflight={initSiteData} />
          }
          {urlParams.has("transferListWidget") &&
              <>
              {(siteData.consent != null && siteData.user != null) &&
              <Card h="100vh" display="flex" style={{flexDirection: "column"}} bg="transparent" shadow="sm">
                <Card.Section withBorder inheritPadding py="xs">
                  <Group position="apart">
                    <Text weight={500}>Made transfers</Text>
                    <Button radius="md" variant="default" id="refreshList" size="xs" onClick={() => setReload(reload + 1)}>Refresh</Button>
                  </Group>
                </Card.Section>
                <Card.Section style={{flex: 1, overflowY: "scroll"}} withBorder inheritPadding py="xs">
                  <TransfersList fullHeight user={siteData.user} reload={reload} />
                </Card.Section>

                {(!siteData.user.authenticated) &&
                    <div>
                      <Grid grow justify="space-between" align="center">
                        <Grid.Col span={6}>
                          <Text weight={500} size="lg" mt="md">
                            You are not logged in. Made transfers may disappear.
                          </Text>
                        </Grid.Col>
                        <Grid.Col span="auto">
                          <Button onClick={() => {const handler = new AuthHandler(); handler.authorize(true);}} variant="default" mt="md" w="100%" color="blue" radius="md">
                            Sign up
                          </Button>
                        </Grid.Col>
                      </Grid>
                      <Text mt="xs" color="dimmed" size="sm">
                        With a free account you can list all transfers you have started in the past and delete them before their expiry date.
                      </Text>
                    </div>
                }

                {(1 === 0 && siteData.user.authenticated && !siteData.user.adFree && siteData.siteData.premium_purchasable) &&
                    <div>
                      <Grid grow justify="space-between" align="center">
                        <Grid.Col span={6}>
                          <Text weight={500} size="lg" mt="md">
                            Don't want to bother your downloaders with ads?
                          </Text>
                        </Grid.Col>
                        <Grid.Col span="auto">
                          <Button onClick={() => {window.open("/pricing?widgetLogin&redirect_to=https://ysendit.com/?transferListWidget", "_parent");}} variant="default" mt="md" w="100%" color="blue" radius="md">
                            Upgrade to Premium
                          </Button>
                        </Grid.Col>
                      </Grid>
                      <Text mt="xs" color="dimmed" size="sm">
                        Disable ads on your transfers and benefit from other exclusive premium features.
                      </Text>
                    </div>
                }


              </Card>
          }
              </>
          }
          {(!urlParams.has("transferListWidget") && isSpace) &&
            <SpacePage title={title} member={member} />
          }
          {(!urlParams.has("transferListWidget") && !isSpace) &&
              <HomePage />
          }

        </websiteContext.Provider>
      </MantineProvider>
  );
}

export default App;
